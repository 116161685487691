import React, { useContext, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ContactUs as Form } from '@red-build/leadcentre';
import { VehicleContext } from '../contexts/VehicleContext';
import J7 from '../assets/thumbnail_1920x800.jpg';
import Video from '../assets/rolaomodajaecoo-j7-hero-video.mp4';
import { BASE_URI } from '../Constants';
import { DealerContext } from '../contexts/DealerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: '16px',
    backgroundColor: 'black',
    '@media (min-width: 1024px)': {
      top: 0,
    },
    color: 'white',
  },
  nav: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  bannerImage: {
    flex: '1',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  statsContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    top: '-35%',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 1023px)': {
      paddingTop: '4px',
    },
    '@media (min-width: 1024px)': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  statsColumn: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid',
    '@media (min-width: 1024px)': {
      paddingTop: '8px',
      textAlign: 'Center',
    },
    '@media (max-width: 1023px)': {
      paddingTop: '4px',
      textAlign: 'Center',
    },
  },
  statsValue: {
    fontSize: '4rem',
    '@media (max-width: 1023px)': {
      fontSize: '1rem',
      contentAlign: 'center',
    },
    '@media (min-width: 1024px)': {
      fontSize: '4rem',
    },
  },
  statsLabel: {
    fontSize: '1.5rem',
    paddingTop: '4px',
    '@media (max-width: 1023px)': {
      fontSize: '0.75rem',
    },
    '@media (min-width: 1024px)': {
      fontSize: '1.5rem',
    },
  },
  banner2Container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginBottom: '14px',
    border: '1px solid',
    position: 'relative',
  },
  banner2Image: {
    flex: '1',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  banner2Text: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '32px',
    '@media (max-width: 767px)': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  banner2TextInner: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    '@media (min-width: 1024px)': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  banner2Title: {
    fontSize: '3rem',
    '@media (max-width: 1023px)': {
      fontSize: '1rem',
    },
    '@media (min-width: 1024px)': {
      fontSize: '3rem',
    },
    textAlign: 'center',
  },
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
    width: '66.666%',
    '@media (max-width: 1023px)': {
      width: '100%',
    },
  },
  carouselImage: {
    position: 'relative',
    width: '100%',
    objectFit: 'contain',
    borderRadius: '2px',
    '@media (min-width: 1024px)': {
      borderRadius: '2px',
    },
  },
  carouselButton: {
    position: 'absolute',
    top: '50%',
    padding: '16px',
    transform: 'translateY(-50%)',
    color: 'white',
    cursor: 'pointer',
    fontSize: '3rem',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  banner3Container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginBottom: '14px',
    border: '1px solid',
    position: 'relative',
  },
  banner3Image: {
    flex: '1',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  banner3Text: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '32px',
    '@media (max-width: 767px)': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  banner3TextInner: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    '@media (min-width: 1024px)': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  banner3Title: {
    fontSize: '3rem',
    '@media (max-width: 1023px)': {
      fontSize: '1rem',
    },
    '@media (min-width: 1024px)': {
      fontSize: '3rem',
    },
    textAlign: 'center',
  },
  ticTacContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    '@media (min-width: 1024px)': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  ticTacItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    maxWidth: '100%',
    justifyContent: 'center',
    paddingTop: '6px',
    '@media (max-width: 1023px)': {
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '14px',
    },
  },
  ticTacImage: {
    width: '50%',
    maxWidth: '100%',
    borderRadius: '4px',
    objectFit: 'contain',
    '@media (max-width: 1023px)': {
      width: '100%',
    },
  },
  ticTacText: {
    width: '50%',
    paddingLeft: '12px',
    paddingRight: '12px',
    '@media (max-width: 1023px)': {
      width: '100%',
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  },
  ticTacTitle: {
    fontSize: '3rem',
    fontWeight: 'medium',
    textAlign: 'center',
    color: 'white',
    '@media (max-width: 1023px)': {
      fontSize: '1rem',
    },
  },
  ticTacDesc: {
    fontSize: '1.5rem',
    fontWeight: 'light',
    textAlign: 'center',
    color: '#c5c5c5',
    paddingTop: '6px',
    '@media (max-width: 1023px)': {
      fontSize: '0.75rem',
    },
  },
  ImgSection: {
    width: '100%',
    height: 'calc(100vh - 63px)',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    background: `url(${J7})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    justifyContent: 'center',
    padding: '0px 100px',
    '@media (min-width:0px) and (max-width:768px)': {
      height: 'calc(50vh - 63px)',
      padding: '50px',
    },
    '@media (min-width:769px) and (max-width:1024px)': {},
  },
  ImgSectionHeading: {
    fontFamily: 'OmodaL',
    fontWeight: 450,
    fontSize: '90px',
    margin: '0px',
    lineHeight: '97px',
    color: 'white',
  },
  ImgSectionHeadingSub: {
    fontSize: '30px',
    margin: '0px',
    color: 'white',
  },
  ImgSectionHeadingBtnHolder: {
    marginTop: '32px',
    marginLeft: '-25px',
    '@media (max-width: 448px)': {
      display: 'none',
    },
  },
  ImgSectionHeadingBtn: {
    fontFamily: 'OmodaR',
    fontWeight: 'lighter',
    marginLeft: '20px',
    padding: '17px 32px',
    cursor: 'pointer',
    border: '1px solid #30363a',
    width: '25%',
  },
  mButtonHolder: {
    display: 'none',
    '@media (max-width: 448px)': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },
  },
  carouselText: {
    fontSize: '2rem',
    width: '100%',
    textAlign: 'center',
    '@media (max-width: 1023px)': {
      fontSize: '1rem',
    },
    '@media (min-width: 1024px)': {
      fontSize: '2rem',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
}));

const DetailedJaecoo = () => {
  const [contactPopUp, setContactPopUp] = useState(false);
  const classes = useStyles();
  const { newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, dealerList } = useContext(DealerContext);

  const tata = {
    vdpCarousel1: [
      {
        img: '/content/VDP/cage.webp',
        title: 'CAGE ENERGY-ABSORBING SPACE CAPSULE',
      },
      {
        img: '/content/VDP/engine.webp',
        title: '1.6GDI EXCELLENT POWER ENGINE',
      },
    ],
    vdpCarousel2: [
      {
        img: '/content/VDP/fast-charging.jpeg',
        title: '50W WIRELESS FAST CHARGING FOR CELLPHONES',
      },
      {
        img: '/content/VDP/big-screen.jpg',
        title: '14.8-INCH LARGE SCREEN',
      },
      {
        img: '/content/VDP/shifter.jpg',
        title: 'AIRCRAFT STYLE GEAR SHIFT HANDLE',
      },
    ],
    vdpTicTac1: [
      {
        img: '/content/VDP/handles.webp',
        title: 'ELECTRIC HIDDEN DOOR HANDLES',
        desc: 'With the electrical release function the door can be closed with a force of 30N',
      },
      {
        img: '/content/VDP/wheels.webp',
        title: '19-INCH GLOSSY ALUMINUM ALLOY HUBS',
        desc: 'It boasts the design that resembles the aurora, making it even more trendy and dazzling',
      },
      {
        img: '/content/VDP/sunroof.webp',
        title: '1.1M2 LARGE PANORAMIC SUNROOF',
        desc: 'With both large size and industry-leading smart open and close functions',
      },
      {
        img: '/content/VDP/seat.jpeg',
        title: 'ALL-SENSE SEATS',
        desc: 'Multi-mode seats are soft and comfortable with ten powerful adjustment functions',
      },
      {
        img: '/content/VDP/airbags.webp',
        title: '8 SAFETY AIRBAGS',
        desc: 'The whole series adopt a 6-second pressure maintaining technology which can effectively prevent secondary injuries from accidents',
      },
    ],
  };

  return (
    <div>
      {contactPopUp && (
        <div className={classes.contactPopUpHolder}>
          <div className={classes.contactPopUpHolderInner}>
            <div
              className={classes.Close}
              onClick={() => {
                setContactPopUp(false);
              }}
            >
              X
            </div>
            <Form
              dealers={dealerList}
              dealerType="multi_dealer"
              dealerId={globalDealer?.dealerId}
              base_uri={BASE_URI}
              leadTypeId={1}
              variantId={newVehiclesList.filter((v) => v.title === 'J7')[0].variantId}
              dealerName={globalDealer?.name?.replace('Master', ' ')}
            />
          </div>
        </div>
      )}
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.nav}>
            {/* <img
              key="Jaecoo"
              className={classes.bannerImage}
              src="/content/VDP/download.webp"
              alt="Hero "
            /> */}
            <div className={classes.Video}>
              <span>
                <video
                  alt="C5 Design Video"
                  src={Video}
                  width="100%"
                  className={classes.vid}
                  autoPlay
                  controls
                  muted
                  onLoadStart={(e) => e.target.play()}
                />
              </span>
            </div>
          </div>
          <div className={classes.statsContainer}>
            <div className={classes.statsColumn}>
              <h3 className={classes.statsValue}>145kW</h3>
              <p className={classes.statsLabel}>POWER</p>
            </div>
            <div className={classes.statsColumn}>
              <h3 className={classes.statsValue}>290N.m</h3>
              <p className={classes.statsLabel}>TORQUE</p>
            </div>
            <div className={classes.statsColumn}>
              <h3 className={classes.statsValue}>2,672mm</h3>
              <p className={classes.statsLabel}>WHEELBASE</p>
            </div>
            <div className={classes.statsColumn}>
              <h3 className={classes.statsValue}>200mm</h3>
              <p className={classes.statsLabel}>GROUND CLEARANCE</p>
            </div>
          </div>
          <div className={classes.ImgSection}>
            <Typography variant="h2" className={classes.ImgSectionHeading}>
              J7
            </Typography>
            <Typography variant="h3" className={classes.ImgSectionHeadingSub}>
              INTELLIGENT TECHNOLOGY CABIN
            </Typography>
            <div className={classes.ImgSectionHeadingBtnHolder}>
              <button
                type="button"
                className={classes.ImgSectionHeadingBtn}
                onClick={() => {
                  window.location.href = '/show-room/JAECOO/918';
                }}
              >
                About Model
              </button>
            </div>
            <div className={classes.mButtonHolder}>
              <button
                type="button"
                className={classes.mHeadingBtn}
                onClick={() => {
                  window.location.href = '/show-room/JAECOO/918';
                }}
              >
                <Typography className={classes.buttonText}>About Model</Typography>
              </button>
            </div>
          </div>
        </div>
        {/* Banner 2 */}
        <div className={classes.banner2Container}>
          <img className={classes.banner2Image} src="/content/VDP/4wd.webp" alt="Hero " />
          <div className={classes.banner2Text}>
            <div className={classes.banner2TextInner}>
              <h3 className={classes.banner2Title}>INTELLIGENT 4WD FOR ALL ROADS</h3>
            </div>
          </div>
        </div>
        {/* Carousel 1 */}
        <div className={classes.carouselContainer}>
          <div className={classes.carousel}>
            {tata.vdpCarousel1.map((slide, index) => (
              <div key={slide.img} className={`generalSlide1 ${index === 0 ? '' : 'hidden'}`}>
                <img className={classes.carouselImage} src={slide.img} alt="Gallery " />
                <p className={classes.carouselText}>{slide.title}</p>
              </div>
            ))}
            <button
              type="button"
              className={classes.carouselButton}
              // onClick={() => moveGeneralSlide(-1)}
            >
              ❮❮
            </button>
            <button
              type="button"
              className={classes.carouselButton}
              // onClick={() => moveGeneralSlide(1)}
            >
              ❯❯
            </button>
          </div>
        </div>
        {/* Banner 3 */}
        <div className={classes.banner3Container}>
          <img className={classes.banner3Image} src="/content/VDP/interior.jpg" alt="Hero " />
          <div className={classes.banner3Text}>
            <div className={classes.banner3TextInner}>
              <h3 className={classes.banner3Title}>INTELLIGENT TECHNOLOGY CABIN</h3>
            </div>
          </div>
        </div>
        {/* Carousel 2 */}
        <div className={classes.carouselContainer}>
          <div className={classes.carousel}>
            {tata.vdpCarousel2.map((slide, index) => (
              <div key={slide.img} className={`generalSlide2 ${index === 0 ? '' : 'hidden'}`}>
                <img className={classes.carouselImage} src={slide.img} alt="Gallery " />
                <p className={classes.carouselText}>{slide.title}</p>
              </div>
            ))}
            <button
              type="button"
              className={classes.carouselButton}
              // onClick={() => moveGeneralSlide2(-1)}
            >
              ❮❮
            </button>
            <button
              type="button"
              className={classes.carouselButton}
              // onClick={() => moveGeneralSlide2(1)}
            >
              ❯❯
            </button>
          </div>
        </div>
        {/* Tic-tac */}
        <div className={classes.ticTacContainer}>
          {tata.vdpTicTac1.map((feature) => (
            <div key={feature.title} className={classes.ticTacItem}>
              <img className={classes.ticTacImage} src={feature.img} alt={feature.title} />
              <div className={classes.ticTacText}>
                <p className={classes.ticTacTitle}>{feature.title}</p>
                <p className={classes.ticTacDesc}>{feature.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailedJaecoo;
